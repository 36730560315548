import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useEffect } from 'react';
import 'react-quill/dist/quill.snow.css';

import { MotionLazy } from '@minimalsComponents/animate/motion-lazy';
import { SnackbarProvider } from '@minimalsComponents/snackbar';
import 'simplebar-react/dist/simplebar.min.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import 'yet-another-react-lightbox/plugins/captions.css';
import 'yet-another-react-lightbox/plugins/thumbnails.css';
import 'yet-another-react-lightbox/styles.css';

import { configureBaseURLAxios } from '@common/api/axios';
import { AppInitializer } from '@common/components/AppInitializer';
import { RdSnackbarStyles } from '@common/uiKit/RdSnackbar/styles';

import { useScrollToTop } from '@src/hooks/use-scroll-to-top';

import { getHostWithPrefix, isHostValid } from './common/utils/url';
import { SettingsProvider } from './components/settings';
import Router from './routes';
import { AuthProvider } from './routes/Auth/authContext';
import ThemeProvider from './theme';

if (!isHostValid()) {
  window.location.href = getHostWithPrefix(import.meta.env.VITE_APP_DOMAIN);
}

export default function App() {
  useScrollToTop();

  useEffect(() => {
    configureBaseURLAxios();
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <AuthProvider>
        <AppInitializer>
          <SettingsProvider
            defaultSettings={{
              themeMode: 'light',
              themeDirection: 'ltr',
              themeContrast: 'default',
              themeLayout: 'vertical',
              themeColorPresets: 'default',
              themeStretch: false,
            }}
          >
            <MotionLazy>
              <ThemeProvider>
                <SnackbarProvider>
                  <RdSnackbarStyles />
                  <Router />
                </SnackbarProvider>
              </ThemeProvider>
            </MotionLazy>
          </SettingsProvider>
        </AppInitializer>
      </AuthProvider>
    </LocalizationProvider>
  );
}
