import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';

import { useAuth } from 'src/routes/Auth/authContext';

const InnactiveUserPage = () => {
  const { logout } = useAuth();
  return (
    <>
      <Box>
        <Typography variant="h3" sx={{ mb: 2 }}>
          You’ve been inactivated
        </Typography>
        <Typography variant="body1" color="text.secondary">
          Sorry, you no longer have access to Received Digital. Please contact an admin to be reactivated.
        </Typography>
      </Box>

      <Box>
        <img src="/images/illustration_inactivated.png" alt="" style={{ padding: '40px 67px 80px' }} />

        <Button
          variant="contained"
          size="large"
          onClick={() => logout()}
        >
          Log Out
        </Button>
      </Box>
    </>
  );
};

export default InnactiveUserPage;
