import { Paper, Stack, TextField } from '@mui/material';
import { useState } from 'react';

import { useCreatePieceCommentMutation, useGetPieceCommentsQuery } from '@api/piece';

import Footer from '@common/components/DrawerContent/Footer';
import Title from '@common/components/DrawerContent/Title';
import { enqueueRdSnackbar } from '@common/uiKit/RdSnackbar';

import Comment from './components/Comment';
import { sxCommentText, sxMain, sxP20, sxPaper } from './styles';

type CommentsProps = {
  pieceId: number;
  onClose: () => void;
};

const Comments = ({ pieceId, onClose }: CommentsProps) => {
  const [comment, setComment] = useState('');
  const { data: comments = [] } = useGetPieceCommentsQuery(pieceId, { skip: !pieceId });
  const [createPieceComment, { isLoading }] = useCreatePieceCommentMutation();

  const postComment = () => {
    if (comment.length) {
      createPieceComment({ pieceId, text: comment })
        .unwrap()
        .then(() => {
          setComment('');
          enqueueRdSnackbar('Comment created', { variant: 'success' });
          onClose();
        })
        .catch(() => {
          enqueueRdSnackbar("Can't create a comment", { variant: 'error' });
        });
    }
  };

  return (
    <Paper elevation={3} sx={sxPaper}>
      <Title title="Comments" onClose={onClose} />
      <Stack sx={sxMain} spacing={3}>
        {comments.map((data) => (
          <Comment key={data.id} data={data} />
        ))}
        <TextField
          label="Comment"
          sx={sxCommentText}
          multiline
          rows={4}
          variant="filled"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
      </Stack>
      <Footer title="Post" sx={sxP20} isLoading={isLoading} handleSubmit={postComment} />
    </Paper>
  );
};

export default Comments;
