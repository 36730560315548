import type {
  Address,
  AddressArrayResponse,
  AddressRequest,
  CreateProfileRequest,
  CreateUserRequest,
  UpdateUserRequest,
  User,
  UserArrayResponse,
  UserFilteredListParams,
  UserMinFilteredListParams,
  UserMinPagedList,
  UserMinPagedListResponse,
  UserPagedList,
  UserPagedListResponse,
  UserResponse,
  UserSetCompanyUpdateParams,
} from '@apiContract';
import isEqual from 'lodash/fp/isEqual';

import { rdBackSignalr } from '@common/api/signalr';
import { getCompanyFromHost, getHostWithPrefix } from '@common/utils/url';

import { receivedDigitalApi } from './index';

const userApi = receivedDigitalApi.injectEndpoints({
  endpoints: (builder) => ({
    getMyProfile: builder.query<User, void>({
      query: () => 'User/Profile',
      providesTags: (result) => (result ? [{ type: 'User', id: result.id }] : ['User']),
      transformResponse: (response: UserResponse) => {
        const data = response.objectData;
        if (data?.company?.subdomain && data.company.subdomain !== getCompanyFromHost()) {
          sessionStorage.removeItem('oidc.default');
          window.location.href = getHostWithPrefix(data.company.subdomain);
        }
        return response.objectData;
      },
      onCacheEntryAdded: (_arg, { updateCachedData }) => {
        rdBackSignalr.registerEvent('OnUserProfileUpdated', 'getMyProfile', (notification: User) => {
          updateCachedData((draft) => {
            draft = notification;
            return draft;
          });
        });
      },
    }),

    createMyProfile: builder.mutation<User, CreateProfileRequest>({
      query: (requestData) => ({
        url: 'User/Profile',
        method: 'POST',
        body: requestData,
      }),
      invalidatesTags: (_, error) => (error ? [] : ['User']),
      transformResponse: (response: UserResponse) => response.objectData,
    }),

    createUser: builder.mutation<User, CreateUserRequest>({
      query: (requestData) => ({
        url: 'User',
        method: 'POST',
        body: requestData,
      }),
      invalidatesTags: ['Users', 'UsersInfinite', 'UsersMin'],
      transformResponse: (response: UserResponse) => response.objectData,
    }),

    updateUser: builder.mutation<User, UpdateUserRequest>({
      query: (requestData) => ({
        url: 'User',
        method: 'PUT',
        body: requestData,
      }),
      invalidatesTags: (result) =>
        result
          ? [{ type: 'User', id: result.id }, 'Users', 'UsersInfinite', 'UsersMin', 'Location', 'PieceAutoAction']
          : ['Users', 'UsersInfinite', 'UsersMin', 'Location', 'PieceAutoAction'],
      transformResponse: (response: UserResponse) => response.objectData,
    }),

    getUsersFiltered: builder.query<UserPagedList, UserFilteredListParams>({
      query: (requestParams) => ({
        url: 'User/filtered',
        params: requestParams,
      }),
      providesTags: (result) =>
        result ? [...(result.items || []).map((user) => ({ type: 'User' as const, id: user.id })), 'Users'] : ['Users'],
      transformResponse: (response: UserPagedListResponse) => response.objectData,
    }),

    getUsersMinFiltered: builder.query<UserMinPagedList, UserMinFilteredListParams>({
      query: (requestParams) => ({
        url: 'User/min-filtered',
        params: requestParams,
      }),
      providesTags: (result) =>
        result
          ? [...(result.items || []).map((user) => ({ type: 'User' as const, id: user.id })), 'UsersMin']
          : ['UsersMin'],
      transformResponse: (response: UserMinPagedListResponse) => response.objectData,
    }),

    updateUsers: builder.mutation<User[], UpdateUserRequest[]>({
      query: (requestData) => ({
        url: 'User/list',
        method: 'PUT',
        body: requestData,
      }),
      invalidatesTags: (result) => [
        ...(result?.map((user) => ({ type: 'User' as const, id: user.id })) || []),
        { type: 'Users' },
        { type: 'UsersInfinite' },
        { type: 'UsersMin' },
        { type: 'Location' },
        { type: 'PieceAutoAction' },
      ],
      transformResponse: (response: UserArrayResponse) => response.objectData,
    }),

    createUserAddress: builder.mutation<Address[], AddressRequest & { userId: string }>({
      query: ({ userId, ...requestData }) => ({
        url: `address/user/${userId}`,
        method: 'POST',
        body: requestData,
      }),
      invalidatesTags: (result, _, requestData) => (result ? [{ type: 'User', id: requestData.userId }] : []),
      transformResponse: (response: AddressArrayResponse) => response.objectData,
    }),

    updateUserAddress: builder.mutation<Address[], AddressRequest & { userId: string }>({
      query: ({ userId, ...requestData }) => ({
        url: `address/user/${userId}`,
        method: 'PUT',
        body: requestData,
      }),
      invalidatesTags: (result, _, requestData) => (result ? [{ type: 'User', id: requestData.userId }] : []),
      transformResponse: (response: AddressArrayResponse) => response.objectData,
    }),

    deleteUserAddress: builder.mutation<Address[], { addressId: number; userId: string }>({
      query: ({ userId, addressId }) => ({
        url: `address/user/${userId}/${addressId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, _, requestData) => (result ? [{ type: 'User', id: requestData.userId }] : []),
      transformResponse: (response: AddressArrayResponse) => response.objectData,
    }),
    setCompanyUser: builder.mutation<UserResponse, UserSetCompanyUpdateParams>({
      query: (params) => ({
        url: 'User/set-company',
        method: 'PUT',
        params,
      }),
    }),
    infiniteLoaderUserQuery: builder.query<UserMinPagedList, UserMinFilteredListParams>({
      query: (requestParams) => ({
        url: 'User/min-filtered',
        params: requestParams,
      }),
      serializeQueryArgs: ({ queryArgs }) => {
        const { PageNumber, ItemsPerPage, ...filters } = queryArgs;
        return JSON.stringify(filters);
      },
      merge: (currentCache, newItems, { arg }) => {
        if (arg.PageNumber === 0) {
          return newItems;
        }

        return {
          ...newItems,
          pageNumber: newItems.pageNumber,
          pageSize: newItems.pageSize,
          totalCount: newItems.totalCount,
          totalPages: newItems.totalPages,
          hasPreviousPage: newItems.hasPreviousPage,
          hasNextPage: newItems.hasNextPage,
          items: [...(currentCache.items || []), ...(newItems.items || [])],
        };
      },
      providesTags: ['UsersInfinite'],
      transformResponse: (response: UserMinPagedListResponse) => response.objectData,
      forceRefetch: ({ currentArg, previousArg }) => {
        if (currentArg?.PageNumber !== previousArg?.PageNumber) {
          return true;
        }

        const { PageNumber: currPage, ...currRest } = currentArg || {};
        const { PageNumber: prevPage, ...prevRest } = previousArg || {};

        return !isEqual(currRest, prevRest);
      },
    }),
  }),
});

export const {
  useGetMyProfileQuery,
  useLazyGetMyProfileQuery,
  useCreateMyProfileMutation,
  useCreateUserMutation,
  useUpdateUserMutation,
  useGetUsersFilteredQuery,
  useGetUsersMinFilteredQuery,
  useUpdateUsersMutation,
  useCreateUserAddressMutation,
  useUpdateUserAddressMutation,
  useDeleteUserAddressMutation,
  useSetCompanyUserMutation,
  useInfiniteLoaderUserQueryQuery,
} = userApi;
