import { useRef, useState } from 'react';

import { AnnotationType, PieceAnnotation } from '@apiContract';
import { getCompanyFromHost } from '../utils/url';
import { useAuth } from 'src/routes/Auth/authContext';

type SearchParams = {
  AnnotationType: string;
  PieceId?: string;
};

export const usePieceAnnotations = (
  onFetch: (annotations: PieceAnnotation[]) => void,
  pieceId: number | null,
) => {
  const { user } = useAuth()
  const accessToken = user?.access_token;
  const currentId = useRef<number | string | null>(null);

  const [isLoading, setIsLoading] = useState(false);

  const url = new URL(`${import.meta.env.VITE_APP_API}/api/piece/annotations/filtered`);

  if (currentId.current !== pieceId) {
    currentId.current = pieceId;
    if (pieceId) {
      setIsLoading(true);
      const params: SearchParams = { PieceId: pieceId.toString(), AnnotationType: AnnotationType.TrackingNumber.toString() };
      url.search = new URLSearchParams(params).toString();
      fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
          'X-Company-Subdomain': getCompanyFromHost(),
        },
      }).then(async (response) => {
        const items = (await response.json()).objectData.items as PieceAnnotation[];
        onFetch(items);
        setIsLoading(false);
      });
    }
  }

  return { isLoading };
};
