import { LoadingButton } from '@mui/lab';
import { Box, SxProps } from '@mui/material';
import { FC } from 'react';

import { sxButton } from './styles';

type FooterProps = {
  disabled?: boolean;
  isLoading?: boolean;
  sx?: SxProps;
  handleSubmit: () => void;
  title?: string;
  onCancel?: () => void;
};

const Footer: FC<FooterProps> = ({ disabled, isLoading = false, sx = {}, handleSubmit, title, onCancel }) => {
  return (
    <Box sx={sx}>
      {onCancel && (
        <LoadingButton variant="outlined" sx={sxButton} size="large" onClick={onCancel}>
          Cancel
        </LoadingButton>
      )}
      <LoadingButton
        disabled={disabled}
        loading={isLoading}
        variant="contained"
        color="primary"
        sx={sxButton}
        size="large"
        onClick={handleSubmit}
      >
        {title || 'Apply'}
      </LoadingButton>
    </Box>
  );
};

export default Footer;
