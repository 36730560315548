import type { Theme } from '@mui/material';

export const sxBackBtn = {
  display: { xs: 'flex', md: 'none' },
  mr: 1,
};

export const sxSearchIcon = {
  color: 'text.disabled',
};

export const sxSearchCircle = (theme: Theme) => ({
  color: theme.palette.grey[500],
});

export const sxButton = {
  px: '22px',
  py: '11px',
  width: 1,
};
