import type {
  BooleanResponse,
  CreateLocationRequest,
  Location,
  LocationListParams,
  LocationPagedList,
  LocationPagedListResponse,
  LocationResponse,
  UpdateLocationRequest,
} from '@apiContract';

import { receivedDigitalApi } from './index';

const locationsApi = receivedDigitalApi.injectEndpoints({
  endpoints: (builder) => ({
    getLocationsApiList: builder.query<LocationPagedList, LocationListParams | undefined | null>({
      query: (params) => ({
        url: 'Location',
        params: params || {},
      }),
      providesTags: ['Location'],
      transformResponse: (response: LocationPagedListResponse) => response.objectData,
    }),
    getLocationById: builder.query<Location, number>({
      query: (id) => `Location/${id}`,
      providesTags: ['Location'],
      transformResponse: (response: LocationResponse) => response.objectData,
    }),
    updateLocation: builder.mutation<Location, UpdateLocationRequest>({
      query: (requestData) => ({
        url: 'Location',
        method: 'PUT',
        body: requestData,
      }),
      invalidatesTags: ['Location', 'Users', 'User', 'UsersInfinite', 'UsersMin'],
      transformResponse: (response: LocationResponse) => response.objectData,
    }),
    createLocation: builder.mutation<Location, CreateLocationRequest>({
      query: (requestData) => ({
        url: 'Location',
        method: 'POST',
        body: requestData,
      }),
      invalidatesTags: ['Location', 'Users', 'User', 'UsersInfinite', 'UsersMin'],
      transformResponse: (response: LocationResponse) => response.objectData,
    }),

    deleteLocation: builder.mutation<Location, number>({
      query: (id) => ({
        url: `Location?id=${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['DeliveryRoutes', 'Location', 'Users', 'User', 'UsersInfinite', 'UsersMin'],
      transformResponse: (response: LocationResponse) => response.objectData,
    }),
    bulkDeleteDeliveryRoute: builder.mutation<boolean, number[]>({
      query: (requestData) => ({
        url: `DeliveryRoutes/list`,
        method: 'DELETE',
        body: requestData,
      }),
      invalidatesTags: ['DeliveryRoutes'],
      transformResponse: (response: BooleanResponse) => response.objectData,
    }),
    bulkDeleteLocations: builder.mutation<boolean, number[]>({
      query: (requestData) => ({
        url: `Location/list`,
        method: 'DELETE',
        body: requestData,
      }),
      invalidatesTags: ['Location', 'Users', 'User'],
      transformResponse: (response: BooleanResponse) => response.objectData,
    }),
  }),
});

export const {
  useGetLocationsApiListQuery,
  useGetLocationByIdQuery,
  useDeleteLocationMutation,
  useBulkDeleteDeliveryRouteMutation,
  useUpdateLocationMutation,
  useCreateLocationMutation,
  useBulkDeleteLocationsMutation,
} = locationsApi;
