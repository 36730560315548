import { Box, Stack, useMediaQuery } from '@mui/material';

import { useGetContainerTypesQuery } from '@api/container';
import { CaptureMethod, ContainerRequest, PieceType, Workflow } from '@apiContract';
import Scrollbar from '@minimalsComponents/scrollbar';

import StorageGroup from '@common/components/StorageGroup';
import { useSettings } from '@common/hooks/useSettings';
import { Options } from '@common/types/capture';
import { SortingOrder, WorkflowEnumByName, WorkflowMail, WorkflowPackages } from '@common/types/contractHelpers';

import {
  captureMethodOptions,
  getDefaultValues,
  itemTypeOptions,
  settingsNames,
  workflowMailOptions,
  workflowPackagesOptions,
} from '../../helper';
import { ReceiveConfigGroup } from './ReceiveConfigGroup';
import { ReceiveToggleButtonGroup } from './ReceiveToggleButtonGroup';
import { sxCenter, sxContainer } from './styles';

type ReceiveSettings = {
  ReceiveMailWorkflow: WorkflowMail;
  ReceivePackagesWorkflow: WorkflowPackages;
  ReceiveSortOrder: SortingOrder;
  LockersWorkflowEnable: boolean;
  DefaultItemType?: 'Mail' | 'Package';
};

type Props = {
  options: Options;
  isLoading: boolean;
  updateReceiveConfig: (data: Partial<Options>) => void;
  setIsLoading: (data: boolean) => void;
};

const getDefaultPieceType = (defaultItemType?: 'Mail' | 'Package') => {
  if (!defaultItemType) return PieceType.Mail;

  return defaultItemType === 'Mail' ? PieceType.Mail : PieceType.Package;
};

const getSettingWorkflowByType = (type: PieceType, receiveSettings: ReceiveSettings) =>
  WorkflowEnumByName[receiveSettings[type === PieceType.Mail ? 'ReceiveMailWorkflow' : 'ReceivePackagesWorkflow']];

const ReceiveConfig = ({ options, isLoading, updateReceiveConfig, setIsLoading }: Props) => {
  const receiveSettings = useSettings(settingsNames) as ReceiveSettings;
  const { data: containerTypes = [], isLoading: loadingContainerTypes } = useGetContainerTypesQuery();
  const matchedMobileDevice = useMediaQuery('(pointer: coarse) and (orientation: landscape)');

  const updateCaptureMethod = (data: CaptureMethod) => {
    updateReceiveConfig({ captureMethod: data });
  };

  const updateStorageGroup = (data: ContainerRequest) => {
    updateReceiveConfig({ storageGroupData: data });
  };

  const onChangeWorkflow = (value: Workflow) => {
    updateReceiveConfig({
      workflow: value,
      storageGroupData: getDefaultValues(containerTypes, receiveSettings)[value],
    });
  };

  const handleChangeItemType = (value: PieceType) => {
    const nextWorkflow = getSettingWorkflowByType(value, receiveSettings);
    updateReceiveConfig({
      pieceType: value,
      workflow: nextWorkflow,
      storageGroupData: getDefaultValues(containerTypes, receiveSettings)[nextWorkflow],
    });
  };

  if (loadingContainerTypes) return null;

  if (!Object.keys(options).length) {
    const captureMethod = CaptureMethod.Single;
    const pieceType = getDefaultPieceType(receiveSettings.DefaultItemType);
    const workflow = getSettingWorkflowByType(pieceType, receiveSettings) || Workflow.DateReceived;
    const storageGroupData = getDefaultValues(containerTypes, receiveSettings)[workflow];
    updateReceiveConfig({
      captureMethod,
      pieceType,
      workflow,
      storageGroupData,
    });
    return null;
  }
  const sxScrollbar = matchedMobileDevice ? { overflow: 'unset' } : {};

  return (
    <Scrollbar sx={sxScrollbar}>
      <Box sx={sxContainer}>
        <Stack spacing={3} sx={sxCenter}>
          <ReceiveConfigGroup title="Capture Method" subtitle="Select how you would like to capture items...">
            <ReceiveToggleButtonGroup
              config={captureMethodOptions}
              value={options.captureMethod || CaptureMethod.Single}
              onChange={updateCaptureMethod}
            />
          </ReceiveConfigGroup>

          <ReceiveConfigGroup title="Item Type" subtitle="Select the type of items you’re receiving...">
            <ReceiveToggleButtonGroup
              config={itemTypeOptions}
              value={options.pieceType || PieceType.Mail}
              onChange={handleChangeItemType}
            />
          </ReceiveConfigGroup>

          <ReceiveConfigGroup
            title="Workflow"
            subtitle="Select the workflow you’re going to use to process these items..."
          >
            <ReceiveToggleButtonGroup
              config={
                (options.pieceType || PieceType.Mail) === PieceType.Mail
                  ? workflowMailOptions
                  : workflowPackagesOptions(receiveSettings.LockersWorkflowEnable)
              }
              value={options.workflow || WorkflowEnumByName[receiveSettings.ReceiveMailWorkflow]}
              onChange={onChangeWorkflow}
            />
          </ReceiveConfigGroup>

          {options.workflow &&
            ![Workflow.HighDensityMail, Workflow.MailBox, Workflow.Lockers].includes(options.workflow) && (
              <ReceiveConfigGroup title="Storage Group" subtitle="Define the storage for this group of items...">
                <StorageGroup
                  key={options.workflow}
                  workflow={options.workflow || WorkflowEnumByName[receiveSettings.ReceiveMailWorkflow]}
                  itemType={options.pieceType || PieceType.Mail}
                  containerTypes={containerTypes}
                  onChange={updateStorageGroup}
                  onLoading={setIsLoading}
                  isLoading={isLoading}
                  defaultValue={
                    options.storageGroupData ||
                    getDefaultValues(containerTypes, receiveSettings)[
                      WorkflowEnumByName[
                        options.pieceType && options.pieceType === PieceType.Package
                          ? receiveSettings.ReceivePackagesWorkflow
                          : receiveSettings.ReceiveMailWorkflow
                      ]
                    ]
                  }
                />
              </ReceiveConfigGroup>
            )}
        </Stack>
      </Box>
    </Scrollbar>
  );
};

export default ReceiveConfig;
