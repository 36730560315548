import { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { User } from 'oidc-client-ts';
import { configureTokenAxios } from 'src/common/api/axios';
import { setCredentials } from 'src/redux/slices/auth';

import { userManager } from './userManager';

const loginAttemptKey = 'oidcLoginAttempt';

type UserContext = {
  user: User | null;
  isLogout: boolean;
  login: () => void;
  logout: () => void;
};

type Props = {
  children: ReactNode;
};

const AuthContext = createContext<UserContext>({
  user: null,
  login: () => {},
  logout: () => {},
  isLogout: false,
});

const isTokenExpired = (user: User) => {
  const currentTime = Math.floor(Date.now() / 1000);
  return user.expires_at && user.expires_at < currentTime;
};

export const AuthProvider = ({ children }: Props) => {
  const [user, setUser] = useState<User | null>(null);
  const [isInitialUserLoading, setIsInitialUserLoading] = useState(false);
  const [isInitialUserLoaded, setInitialIsUserLoaded] = useState(false);
  const [isLogout, setIsLogout] = useState(false);

  const dispatch = useDispatch();

  const logout = async () => {
    sessionStorage.setItem(loginAttemptKey, '0');
    setIsLogout(true);
    userManager.signoutRedirect({ id_token_hint: user?.id_token });
  };

  const login = async () => {
    const attemptCount = +(sessionStorage.getItem(loginAttemptKey) || 0);
    if (attemptCount > 2) {
      logout();
      return;
    }
    sessionStorage.setItem(loginAttemptKey, `${attemptCount + 1}`);
    const returnUrl = window.location.pathname + window.location.search;
    await userManager.signinRedirect({ state: returnUrl });
  }

  if (user && isTokenExpired(user)) {
    logout();
  }

  if (!isInitialUserLoaded && !isInitialUserLoading) {
    setIsInitialUserLoading(true);
    userManager
      .getUser()
      .then((storedUser) => {
        if (storedUser && !isTokenExpired(storedUser)) {
          setUser(storedUser);
          dispatch(setCredentials(storedUser?.access_token));
          configureTokenAxios(storedUser?.access_token);
        }
        setInitialIsUserLoaded(true);
        setIsInitialUserLoading(false);
      })
      .catch(() => {
        setUser(null);
        setInitialIsUserLoaded(true);
        setIsInitialUserLoading(false);
      });
  }

  const clearUser = () => {
    setUser(null);
    dispatch(setCredentials(''));
    configureTokenAxios('');
  };

  useEffect(() => {
    const onUserLoaded = (loadedUser: User) => {
      dispatch(setCredentials(loadedUser?.access_token));
      configureTokenAxios(loadedUser?.access_token);
      setUser(loadedUser);
    };

    userManager.events.addUserLoaded(onUserLoaded);
    userManager.events.addUserUnloaded(clearUser);
    userManager.events.addAccessTokenExpired(clearUser);

    return () => {
      userManager.events.removeUserLoaded(onUserLoaded);
      userManager.events.removeUserUnloaded(clearUser);
      userManager.events.removeAccessTokenExpired(clearUser);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider value={{ user, login, logout, isLogout }}>
      {isInitialUserLoaded && !isInitialUserLoading && children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
