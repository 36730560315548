import { useEffect } from 'react';

import { useAuth } from 'src/routes/Auth/authContext';

import { configureTokenAxios } from '@common/api/axios';

import { setCredentials } from '@redux/slices/auth';
import { useDispatch } from '@redux/store';

type AppInitializerProps = {
  children: React.ReactNode;
};

export const AppInitializer = ({ children }: AppInitializerProps) => {
  const { user } = useAuth();
  const accessToken = user ? user.access_token : null;

  const dispatch = useDispatch();

  useEffect(() => {
    if (accessToken) {
      dispatch(setCredentials(accessToken));
      configureTokenAxios(accessToken);
    }
  }, [accessToken, dispatch]);

  return <>{children}</>;
};
