import { Navigate, Outlet, useRoutes } from 'react-router-dom';

import CompactLayout from '@minimalsLayouts/compact';
import DashboardLayout from '@minimalsLayouts/dashboard';
import { SessionLost } from 'src/common/components/SessionLost';
import { CaptureProvider } from 'src/common/contexts/CaptureContext';

import Capture from '@common/components/Capture';
import { SettingsProvider } from '@common/contexts/SettingsContext';

import { DefaultRecirect } from './DefaultRedirect';
import { AccountSetupGuard } from './guards/AccountSetupGuard';
import GuestGuard from './guards/GuestGuard';
import { RoleBasedGuard } from './guards/RoleBasedGuard';
import { UserProfileGuard } from './guards/UserProfileGuard';
import {
  AdministrationAccountSetup,
  AdministrationAutomationCreate,
  AdministrationAutomationEdit,
  AdministrationAutomations,
  AdministrationCompany,
  AdministrationLocation,
  AdministrationLocations,
  AdministrationMailroom,
  AdministrationPurchasePlan,
  AdministrationRoute,
  AdministrationRoutes,
  AdministrationUserCreate,
  AdministrationUserEdit,
  AdministrationUsersManagement,
  AdministrationUsersUpload,
  AuthCallback,
  AuthRegister,
  Inbox,
  Mailpiece,
  MailroomActiveItems,
  MailroomActiveStorageGroups,
  MailroomDashboard,
  MailroomPendingRequests,
  MailroomReceiveSessions,
  MailroomSearch,
  MailroomStorageGroupContent,
  Onboarding,
  Page404,
  Page500,
  UserAccount,
} from './pages';
import { administrationRoutes, userRoutes } from './paths';

export default function Router() {
  return useRoutes([
    {
      element: (
        <UserProfileGuard>
          <SettingsProvider>
            <Outlet />
          </SettingsProvider>
        </UserProfileGuard>
      ),
      children: [
        {
          index: true,
          element: <DefaultRecirect />,
        },

        {
          path: 'administration/account-setup',
          element: <AdministrationAccountSetup />,
        },
        {
          element: (
            <AccountSetupGuard>
              <DashboardLayout>
                <Outlet />
              </DashboardLayout>
            </AccountSetupGuard>
          ),
          children: [
            {
              path: 'superadmin/onboarding',
              element: (
                <RoleBasedGuard accessibleRoles={['KTAdmin']}>
                  <Onboarding />
                </RoleBasedGuard>
              ),
            },

            {
              path: 'administration',
              element: (
                <RoleBasedGuard accessibleRoles={['Admin', 'KTAdmin']}>
                  <Outlet />
                </RoleBasedGuard>
              ),
              children: [
                {
                  path: 'company',
                  children: [
                    { index: true, element: <Navigate to={administrationRoutes.company('info')} replace /> },
                    { path: ':tab', element: <AdministrationCompany /> },
                    { path: 'purchase-plan', element: <AdministrationPurchasePlan /> },
                  ],
                },
                {
                  path: 'mailroom',
                  children: [
                    { index: true, element: <AdministrationMailroom /> },
                    { path: ':tab', element: <AdministrationMailroom /> },
                  ],
                },
                {
                  path: 'users',
                  children: [
                    { index: true, element: <AdministrationUsersManagement /> },
                    { path: 'create', element: <AdministrationUserCreate /> },
                    { path: 'edit/:id', element: <AdministrationUserEdit /> },
                    { path: 'upload', element: <AdministrationUsersUpload /> },
                  ],
                },
                {
                  path: 'automations',
                  children: [
                    { index: true, element: <AdministrationAutomations /> },
                    { path: 'create', element: <AdministrationAutomationCreate /> },
                    { path: 'edit/:id', element: <AdministrationAutomationEdit /> },
                  ],
                },
                {
                  path: 'delivery/routes',
                  children: [
                    { index: true, element: <AdministrationRoutes /> },
                    { path: 'create', element: <AdministrationRoute /> },
                    { path: ':id', element: <AdministrationRoute /> },
                  ],
                },
                {
                  path: 'delivery/locations',
                  children: [
                    { index: true, element: <AdministrationLocations /> },
                    { path: 'create', element: <AdministrationLocation /> },
                    { path: ':id', element: <AdministrationLocation /> },
                  ],
                },

                // AdministrationRoutes
              ],
            },

            {
              path: 'user/account',
              children: [
                { index: true, element: <Navigate to={userRoutes.account('general')} replace /> },
                { path: ':label', element: <UserAccount /> },
              ],
            },

            {
              path: 'mailroom',
              element: (
                <RoleBasedGuard accessibleRoles={['Mailroom']}>
                  <CaptureProvider>
                    <Outlet />
                    <Capture />
                  </CaptureProvider>
                </RoleBasedGuard>
              ),
              children: [
                { path: 'dashboard', element: <MailroomDashboard /> },
                { path: 'receive', element: <MailroomReceiveSessions /> },
                { path: 'requests', element: <MailroomPendingRequests /> },
                {
                  path: 'active',
                  children: [
                    {
                      path: 'items',
                      element: <MailroomActiveItems />,
                    },
                    {
                      path: 'storage-groups',
                      element: <MailroomActiveStorageGroups />,
                    },
                    {
                      path: 'storage-groups/:id',
                      element: <MailroomStorageGroupContent />,
                    },
                  ],
                },
                { path: 'search', element: <MailroomSearch /> },
                { path: ':mailpieceCategory/:mailpieceId', element: <Mailpiece type="Mailroom" /> },
              ],
            },

            {
              path: 'inbox',
              element: (
                <RoleBasedGuard accessibleRoles={['Recipient']}>
                  <Outlet />
                </RoleBasedGuard>
              ),
              children: [
                { path: ':label', element: <Inbox /> },
                { path: ':mailpieceCategory/:mailpieceId', element: <Mailpiece type="Inbox" /> },
              ],
            },
          ],
        },
      ],
    },

    // Public routes:

    {
      path: 'auth/register',
      element: (
        <GuestGuard>
          <AuthRegister />
        </GuestGuard>
      ),
    },

    {
      element: (
        <CompactLayout>
          <Outlet />
        </CompactLayout>
      ),
      children: [
        { path: '404', element: <Page404 /> },
        // TODO: Looks like this page is unused, discuss...
        { path: '500', element: <Page500 /> },
        { path: 'session-timed-out', element: <SessionLost withLogout /> },
        { path: 'auth/callback', element: <AuthCallback /> },
      ],
    },

    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
