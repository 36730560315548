import { Autocomplete, Box, TextField } from '@mui/material';
import { BoxOwnProps, Theme } from '@mui/system';
import { forwardRef } from 'react';

import { useGetLocationsApiListQuery } from '@api/locations';
import { Location } from '@apiContract';

type Props = {
  value?: number | null;
  onLocationChange: (location: number | null) => void;
  sx?: BoxOwnProps<Theme>;
  disabled?: boolean;
};

const AutocompleteLocation = forwardRef<HTMLDivElement, Props>(
  ({ disabled, value, onLocationChange, sx, ...props }, ref) => {
    const { data } = useGetLocationsApiListQuery({ ItemsPerPage: 1000000, SortBy: 'Name' });

    const onChange = (location: Location | null) => {
      onLocationChange(location ? location.id : null);
    };

    return (
      <Box sx={sx} ref={ref} {...props}>
        <Autocomplete
          value={data?.items?.find((location) => location.id === value) ?? null}
          fullWidth
          disabled={!!disabled}
          onChange={(_, changeValue) => onChange(changeValue)}
          options={data?.items || []}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => <TextField {...params} label="Location" />}
        />
      </Box>
    );
  },
);

export default AutocompleteLocation;
