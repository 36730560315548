import AddIcon from '@mui/icons-material/Add';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import {
  Avatar,
  Box,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
  useTheme,
} from '@mui/material';
import { CSSProperties, FC } from 'react';

import { User } from '@apiContract';

import { sxAvatar, sxCheckOutIcon, sxIconGrey500, sxItemAvatar, sxItemButton, sxSelectedBox } from '../../styles';

type ListItemProps = {
  selected: boolean;
  isUnassign?: boolean;
  style: CSSProperties;
  item: User | null;
  handleClick: () => void;
  changeTab: () => void;
};

const Item: FC<ListItemProps> = ({ style, selected, item, handleClick, changeTab }) => {
  const theme = useTheme();

  return (
    <ListItem component="div" sx={style} disablePadding>
      <ListItemButton
        onClick={item ? handleClick : changeTab}
        sx={{ ...sxItemButton(selected, theme) }}
        data-testid="rd-assign-to-user"
      >
        <ListItemAvatar sx={sxItemAvatar}>
          <Avatar sx={sxAvatar} alt={`${item?.firstName} ${item?.lastName}`} src={item?.profileImageUrl}>
            {item && !item.profileImageUrl ? (
              `${item?.firstName?.charAt(0)}${item?.lastName?.charAt(0)}`
            ) : (
              <AddIcon sx={sxIconGrey500} />
            )}
          </Avatar>
          {selected ? (
            <Box sx={sxSelectedBox}>
              <CheckOutlinedIcon sx={sxCheckOutIcon} />
            </Box>
          ) : null}
        </ListItemAvatar>
        {item ? (
          <ListItemText
            primary={
              <Typography
                title={item.displayName}
                variant="subtitle2"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                overflow="hidden"
              >
                {item.displayName}
              </Typography>
            }
            secondary={
              <Typography
                title={item?.userName}
                color="text.secondary"
                variant="body2"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                overflow="hidden"
              >
                {item?.userName}
              </Typography>
            }
          />
        ) : (
          <Typography variant="subtitle2">Create New User</Typography>
        )}
      </ListItemButton>
    </ListItem>
  );
};

export default Item;
