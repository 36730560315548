import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Box, Divider, IconButton, SxProps, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';

import { sxBackBtn } from './styles';

type TitleProps = {
  sx?: SxProps;
  title: string;
  onClose: () => void;
  count?: number;
  rightAdornment?: ReactNode;
};

const Title: FC<TitleProps> = ({ sx = {}, title, onClose, count, rightAdornment }) => {
  return (
    <>
      <Box m={2} display="flex" alignItems="center" flexDirection="row" justifyContent="space-between" sx={sx}>
        <Box my={0.5} display="flex" alignItems="center" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
          <IconButton onClick={onClose} sx={sxBackBtn}>
            <ChevronLeftIcon />
          </IconButton>
          <Typography variant="h6" textTransform="capitalize">
            {title}
          </Typography>
          {count !== undefined && (
            <Typography variant="h6" fontWeight="400">
              &nbsp;({count})
            </Typography>
          )}
        </Box>
        {!!rightAdornment && rightAdornment}
      </Box>
      <Divider />
    </>
  );
};

export default Title;
