import { FC } from 'react';
import EmailIcon from '@mui/icons-material/Email';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { Avatar, Button, CardContent, Typography, Card } from '@mui/material';
import { Box, Stack } from '@mui/system';

import { useAuth } from 'src/routes/Auth/authContext';

type Props = {
  isCreatingProfile: boolean;
  createUserProfile: () => Promise<void>;
}

const CreateProfilePage: FC<Props> = ({ createUserProfile, isCreatingProfile }) => {
  const { user, logout, login } = useAuth();

  const confirmProfileCreation = async () => {
    await createUserProfile();
    login();
  };
  return (
    <>
      <Box>
        <Typography variant="h3" sx={{ mb: 2 }}>
          Create a new company?
        </Typography>
        <Typography variant="body1" color="text.secondary">
          Would you like to create a new company in Received Digital using the Digitalized Software account below?
        </Typography>
      </Box>

      <Card>
        <CardContent sx={{ padding: '40px 52px !important' }}>
          <Avatar sx={{ width: 128, height: 128, mx: 'auto', mb: 3 }} />
          <Stack spacing={1}>
            <Typography variant="h6">{`${user?.profile.given_name} ${user?.profile.family_name}`}</Typography>
            <Typography variant="body2" color="text.secondary">
              <EmailIcon fontSize="small" sx={{ verticalAlign: 'middle', mr: 1 }} />
              {user?.profile.email}
            </Typography>
            {user?.profile.phone_number && (
              <Typography variant="body2" color="text.secondary">
                <LocalPhoneIcon fontSize="small" sx={{ verticalAlign: 'middle', mr: 1 }} />
                {user?.profile.phone_number}
              </Typography>
            )}
          </Stack>
        </CardContent>
      </Card>

      <Stack direction="row" spacing={2}>
        <Button
          variant="outlined"
          color="inherit"
          size="large"
          startIcon={<KeyboardArrowLeftIcon />}
          sx={{ flex: '1 1' }}
          onClick={() => logout()}
        >
          Log Out
        </Button>
        <Button
          variant="contained"
          size="large"
          sx={{ flex: '1 1' }}
          onClick={() => confirmProfileCreation()}
          disabled={isCreatingProfile}
        >
          Create Company
        </Button>
      </Stack>
    </>
  );
};

export default CreateProfilePage;
