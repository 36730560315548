import { useCallback, useEffect, useState } from 'react';
import { enqueueRdSnackbar } from '@common/uiKit/RdSnackbar';

import isArray from 'lodash/fp/isArray';

import { OnFileAddParams, OnPieceCreateType } from '../types';
import webWorker from './uploadImageWorker';
import { resizeImage } from 'src/common/utils/image';
import { toBase64 } from '../helper';
import { useAuth } from 'src/routes/Auth/authContext';

export const useUploadWorker = (cb: (params: OnPieceCreateType) => void) => {
  const [worker, setWorker] = useState<Worker | null>(null);
    const { user } = useAuth()
    const accessToken = user?.access_token;

  useEffect(() => {
    const code = webWorker.toString();
    const blob = new Blob(['(' + code + ')()']);
    const myWorker = new Worker(URL.createObjectURL(blob));

    setWorker(myWorker);

    myWorker.postMessage({ type: 'setup', payload: { accessToken, url: import.meta.env.VITE_APP_API } });

    return () => {
      myWorker.terminate();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (worker) {
      worker.postMessage({ type: 'setup', payload: { accessToken, url: import.meta.env.VITE_APP_API } });
    }
  }, [accessToken, worker]);

  if (worker) {
    worker.onmessage = function (event) {
      switch (event.data.type) {
        case 'pieceCreated':
        case 'startProcessing':
        case 'error':
          cb?.(event.data.payload);
          break;
        case 'fetchError': 
          enqueueRdSnackbar(event.data.payload, { variant: 'error' });
          break;
        default:
          break;
      }
    };
  }

  const addFile = useCallback(
    async (payload: OnFileAddParams | Array<OnFileAddParams>) => {
      let nextPayload = null;
      if (!window.OffscreenCanvas) {
        if (isArray(payload)) {
          nextPayload = [];
          for (const item of payload) {
            const nextBase = await toBase64(item.file as File);
            const nextItem = { ...item, resize: true };
            nextItem.file = await resizeImage(nextBase as string, 2000, 2000);
            nextPayload.push(nextItem);
          }
        } else {
          nextPayload = { ...payload, resize: true };
          nextPayload.file = await resizeImage(payload.file as string, 2000, 2000);
          nextPayload.resize = false;
        }
      }
      worker?.postMessage({ type: 'addFile', payload: nextPayload || payload });
    },
    [worker],
  );

  return { addFile };
};
