// @mui
import { Box, Button, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';

import { m } from 'framer-motion';

// components
import { MotionContainer, varBounce } from '../components/animate';
import { useAuth } from 'src/routes/Auth/authContext';

// ----------------------------------------------------------------------

export default function PageUnavailable() {
  const { logout } = useAuth();
  return (
    <>
      <Helmet>
        <title>Page unavailable | Received Digital</title>
      </Helmet>

      <MotionContainer sx={{ width: 480 }}>
        <m.div variants={varBounce().in}>
          <Typography variant="h3" paragraph>
            Page unavailable
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <Typography sx={{ color: 'text.secondary' }}>
            Please check that you’ve entered the correct URL, or contact your administrator for help.
          </Typography>
        </m.div>

        <m.div variants={varBounce().in}>
          <Box sx={{ my: 10 }}>
            <img src="/assets/illustrations/illustration_invalid_url.png" />
          </Box>
        </m.div>
        <Button variant="contained" color="primary" size="large" onClick={logout}>
          Return to Login
        </Button>
      </MotionContainer>
    </>
  );
}
