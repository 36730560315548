import { useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';

import { useSettings } from '@common/hooks/useSettings';

import { CropEdges } from './components/CropEdges';
import { CropMagnifier } from './components/CropMagnifier';
import { CropPoint } from './components/CropPoint';
import { useOpenCvTools } from './hooks/useOpenCvTools';
import CrossOriginImg from 'src/common/uiKit/CrossOriginImg';

export type Coordinates = {
  x: number;
  y: number;
};

export type ContainerSize = {
  width: number;
  height: number;
};

export type CropPointId = 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight';

export type CropPointsCoordinates = {
  [key in CropPointId]: Coordinates;
};

export interface ICropperRef {
  done: () => Promise<any>;
}

export const points: CropPointId[] = ['topLeft', 'topRight', 'bottomLeft', 'bottomRight'];

const defaultCropPointsCoordinates: CropPointsCoordinates = {
  topLeft: { x: 0, y: 0 },
  topRight: { x: 1, y: 0 },
  bottomLeft: { x: 0, y: 1 },
  bottomRight: { x: 1, y: 1 },
};

const settingName = ['ComputerVision'];

interface CanvasProps {
  image?: string;
  cropperRef: React.MutableRefObject<ICropperRef | null> | undefined;
  setOpenCvIsLoading?: (status: boolean) => void;
}

export const ImageCropper = ({ image, cropperRef, setOpenCvIsLoading }: CanvasProps) => {
  const cropContainerRef = useRef<HTMLDivElement>(null);

  const [imageElement, setImageElement] = useState<HTMLImageElement | null>(null);
  const [cropContainerSize, setCropContainerSize] = useState<ContainerSize>({ width: 0, height: 0 });
  const [detectedCornersCoordinates, setDetectedCornersCoordinates] = useState<CropPointsCoordinates | null>(null);
  const [relativeCropPointsCoordinates, setRelativeCropPointCoordinates] =
    useState<CropPointsCoordinates>(defaultCropPointsCoordinates);
  const [magnifierPoint, setMagnifierPoint] = useState<CropPointId | null>(null);
  const [aspectRatio, setAspectRatio] = useState<number>(0);
  const [cropPointsPristine, setCropPointsPristine] = useState<boolean>(true);

  const { loaded: openCvLoaded, detectCorners, transform } = useOpenCvTools();

  const { ComputerVision: computerVisionActive } = useSettings(settingName) as {
    ComputerVision: boolean;
  };

  const resizeObserver = useMemo(
    () =>
      new ResizeObserver((entries) => {
        entries.forEach((entry) => {
          setCropContainerSize({
            width: entry.contentRect.width,
            height: entry.contentRect.height,
          });
        });
      }),
    [],
  );

  useEffect(() => {
    setOpenCvIsLoading?.(!openCvLoaded);
  }, [openCvLoaded, setOpenCvIsLoading]);

  useEffect(() => {
    if (!cropContainerRef.current) return;
    resizeObserver.observe(cropContainerRef.current);

    return () => {
      resizeObserver.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (openCvLoaded && imageElement && aspectRatio && computerVisionActive) {
      const cornerCoodrinates = detectCorners(imageElement);
      setDetectedCornersCoordinates(cornerCoodrinates);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openCvLoaded, imageElement, aspectRatio, computerVisionActive]);

  useEffect(() => {
    if (detectedCornersCoordinates && cropPointsPristine) setRelativeCropPointCoordinates(detectedCornersCoordinates);
  }, [detectedCornersCoordinates, cropPointsPristine]);

  const cropPointsCoordinates: CropPointsCoordinates = useMemo(() => {
    return {
      topLeft: {
        x: cropContainerSize.width * relativeCropPointsCoordinates.topLeft.x,
        y: cropContainerSize.height * relativeCropPointsCoordinates.topLeft.y,
      },
      topRight: {
        x: cropContainerSize.width * relativeCropPointsCoordinates.topRight.x,
        y: cropContainerSize.height * relativeCropPointsCoordinates.topRight.y,
      },
      bottomLeft: {
        x: cropContainerSize.width * relativeCropPointsCoordinates.bottomLeft.x,
        y: cropContainerSize.height * relativeCropPointsCoordinates.bottomLeft.y,
      },
      bottomRight: {
        x: cropContainerSize.width * relativeCropPointsCoordinates.bottomRight.x,
        y: cropContainerSize.height * relativeCropPointsCoordinates.bottomRight.y,
      },
    };
  }, [cropContainerSize, relativeCropPointsCoordinates]);

  useImperativeHandle(cropperRef, () => ({
    done: async () => {
      return new Promise((resolve, reject) => {
        if (!imageElement) {
          reject('There is no source image.');
          return;
        }
        const dataURL = transform(imageElement, cropPointsCoordinates, cropContainerSize.width / imageElement.width);
        if (!dataURL) reject('There is no image.');
        resolve(dataURL);
      });
    },
  }));

  const onDrag = useCallback(
    (position: Coordinates, pointId: CropPointId) => {
      const { x, y } = position;
      setRelativeCropPointCoordinates((nextPoints) => ({
        ...nextPoints,
        [pointId]: { x: x / cropContainerSize.width, y: y / cropContainerSize.height },
      }));
      setMagnifierPoint(pointId);
      setCropPointsPristine(false);
    },
    [cropContainerSize],
  );

  const onStop = useCallback(
    (position: Coordinates, pointId: CropPointId) => {
      const { x, y } = position;
      setRelativeCropPointCoordinates((nextPoints) => ({
        ...nextPoints,
        [pointId]: { x: x / cropContainerSize.width, y: y / cropContainerSize.height },
      }));
      setMagnifierPoint(null);
    },
    [cropContainerSize],
  );

  return (
    <div
      className="rd-image-cropper"
      style={{
        position: 'relative',
        maxHeight: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        aspectRatio: `${aspectRatio}`,
      }}
      ref={cropContainerRef}
    >
      {points.map((pointId) => (
        <CropPoint
          key={pointId}
          x={cropPointsCoordinates[pointId].x}
          y={cropPointsCoordinates[pointId].y}
          pointId={pointId}
          onDrag={onDrag}
          onStop={onStop}
        />
      ))}

      <CropEdges previewSize={cropContainerSize} cropPoints={cropPointsCoordinates} />

      {imageElement && (
        <CropMagnifier
          image={imageElement}
          containerSize={cropContainerSize}
          coordinates={magnifierPoint ? cropPointsCoordinates[magnifierPoint] : null}
        />
      )}

      <CrossOriginImg
        src={image}
        onLoad={(event) => {
          const imgElement = event.target as HTMLImageElement;
          setImageElement(imgElement as HTMLImageElement);
          setAspectRatio(imgElement.width / imgElement.height);
        }}
      />

    </div>
  );
};
