import { Box, CircularProgress } from '@mui/material';

import { User } from '@apiContract';
import { OpenCvProvider } from 'opencv-react';

import { ICropperRef, ImageCropper } from '@common/components/ImageCropper';
import { ImagePreview } from '@common/uiKit/ImagePreview';

import { PieceAnnotations } from '../Capture/types';
import RecipientNotification from '../RecipientNotification';
import { sxCircleProgress, sxImageEditor } from './styles';
import { useState } from 'react';

interface ImageEditorProps {
  imageBase64: string;
  croppingActive: boolean;
  cropperRef: React.MutableRefObject<ICropperRef | null> | undefined;
  actions: React.ReactNode;
  setOpenCvIsLoading?: (status: boolean) => void;
  isLoading?: boolean;
  annotations?: PieceAnnotations;
  recipient?: User;
  forceFetch?: boolean;
}

export const ImageEditor = ({
  imageBase64,
  actions,
  croppingActive = false,
  cropperRef,
  setOpenCvIsLoading,
  isLoading,
  annotations,
  recipient,
  forceFetch,
}: ImageEditorProps) => {

  const [image, setImage] = useState<string>();
  const [isForceFetchLoading, setIsForceFetchLoading] = useState(false);

  if (!image && forceFetch && !isForceFetchLoading) {
    setIsForceFetchLoading(true);
    fetch(imageBase64, {
      headers: {
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        'Pragma': 'no-cache',
        'Expires': '0'
      }
    }).then((response) => {
      return response.blob();
    }).then(imageBlob => {
      const imageObjectURL = URL.createObjectURL(imageBlob);
      setImage(imageObjectURL);
      setIsForceFetchLoading(false);
    });
  }

  const isLoadingShow = (forceFetch && !image) || isForceFetchLoading || isLoading;

  if (isLoadingShow) return (
    <Box sx={sxCircleProgress}>
      <CircularProgress size={80} />
    </Box>
  );

  return (
    <Box className="rd-image-editor" sx={sxImageEditor(croppingActive)}>
      {(croppingActive) ? (
        <OpenCvProvider>
          <ImageCropper image={forceFetch ? image : imageBase64} cropperRef={cropperRef} setOpenCvIsLoading={setOpenCvIsLoading} />
        </OpenCvProvider>
      ) : (
        <ImagePreview imageDataUrl={imageBase64} annotations={annotations} />
      )}

      {actions}

      {recipient && <RecipientNotification recipient={recipient} />}
    </Box>
  );
};
