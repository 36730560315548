import { Container, Stack } from '@mui/material';
import { useCallback, useEffect } from 'react';

import { useCreateMyProfileMutation, useLazyGetMyProfileQuery } from '@api/user';
import Header from '@minimalsLayouts/common/header-simple';
import * as Sentry from '@sentry/react';
import { getCompanyFromHost } from 'src/common/utils/url';
import CreateProfilePage from 'src/pages/CreateProfile';
import InnactiveUserPage from 'src/pages/InnactiveUser';
import PageErrorMFA from 'src/pages/PageErrorMFA';
import PageUnavailable from 'src/pages/PageUnavailable';

import { rdBackSignalr } from '@common/api/signalr';
import LoadingScreen from '@common/components/LoadingScreen';
import { enqueueRdSnackbar } from '@common/uiKit/RdSnackbar';

import { useAuth } from '../Auth/authContext';
import { userManager } from '../Auth/userManager';

interface UserProfileGuardProps {
  children: React.ReactElement;
}

const PageWrapper = ({ children }: UserProfileGuardProps) => (
  <>
    <Header />

    <Container component="main">
      <Stack
        spacing={5}
        sx={{
          py: 12,
          m: 'auto',
          maxWidth: 480,
          minHeight: '100vh',
          textAlign: 'center',
          justifyContent: 'center',
        }}
      >
        {children}
      </Stack>
    </Container>
  </>
);

const getProfileErrorPage = (error: string) => {
  switch (error) {
    case 'ProfileNotActive':
      return <InnactiveUserPage />;

    case 'CompanyRequiresMfa': {
      enqueueRdSnackbar('serverError', { variant: 'error' });
      return <PageErrorMFA />;
    }

    default:
      return <PageUnavailable />;
  }
};

export const UserProfileGuard = ({ children }: UserProfileGuardProps) => {
  const { user, login, isLogout } = useAuth();
  const [createMyProfile, { isLoading: isCreatingProfile, data: createProfileData, error: profileCreationError }] =
    useCreateMyProfileMutation();
  const [triggerGetMyProfile, { data: userProfile, error: userProfileError, isLoading: isLoadingUserProfile }] =
    useLazyGetMyProfileQuery();

  const createUserProfile = useCallback(async () => {
    if (!user || !user.profile) return;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { email, given_name, family_name } = user.profile;
    if (!email || !given_name || !family_name) return;
    await createMyProfile({
      email,
      firstName: given_name,
      lastName: family_name,
    }).unwrap();
    await userManager.signinSilent();
  }, [user, createMyProfile]);

  const accessToken = user?.access_token;

  useEffect(() => {
    if (!accessToken || !userProfile || rdBackSignalr.connectionState !== 0 || !user) return;
    rdBackSignalr.connect(accessToken);

    return () => {
      rdBackSignalr.disconnect();
    };
  }, [accessToken, user, userProfile]);

  useEffect(() => {
    if (!userProfile) return;
    Sentry.setUser({
      id: userProfile.id,
      username: `${userProfile.firstName} ${userProfile.lastName} (${userProfile.company?.name || '-'}: ${
        userProfile.userName
      })`,
      email: userProfile.email,
    });
  }, [userProfile]);

  if ((!user && !isLogout) || (userProfileError && 'status' in userProfileError && userProfileError?.status === 401)) {
    login();
    return <LoadingScreen />;
  }

  if (!userProfile && !userProfileError && !isLoadingUserProfile) {
    triggerGetMyProfile();
  }

  if (userProfileError && 'data' in userProfileError) {
    // Profile not created or no company register on our domain
    if (
      ['ProfileNotCreated', 'UnacceptableRole'].includes(userProfileError.data as string) &&
      !createProfileData &&
      getCompanyFromHost() === import.meta.env.VITE_APP_DOMAIN
    ) {
      if (profileCreationError) {
        return (
          <PageWrapper>
            <PageUnavailable />
          </PageWrapper>
        );
      }
      const role = user?.profile?.role as string;
      if (role?.includes('ReceivedDigitalUser') && !isCreatingProfile) {
        createUserProfile();
        return <LoadingScreen />;
      }
      return (
        <PageWrapper>
          <CreateProfilePage createUserProfile={createUserProfile} isCreatingProfile={isCreatingProfile} />
        </PageWrapper>
      );
    }

    return <PageWrapper>{getProfileErrorPage(userProfileError?.data as string)}</PageWrapper>;
  }

  if (
    (!userProfile && isLoadingUserProfile) ||
    isCreatingProfile ||
    (userProfile?.company && getCompanyFromHost() !== userProfile?.company?.subdomain)
  ) {
    return <LoadingScreen />;
  }

  return children;
};
