import { UserManager } from 'oidc-client-ts';

const config = {
  authority: import.meta.env.VITE_APP_IDENTITY,
  client_id: 'react',
  redirect_uri: window.location.origin + '/auth/callback',
  post_logout_redirect_uri: window.location.origin,
  response_type: 'code',
  scope: 'openid offline_access dm_api profile email roles phone',
  loadUserInfo: true,
};

export const userManager = new UserManager(config);